/**
 * Add horizontal margin to elements.
 *
 * @mixin
 * @param $sx the margin left value. Default value is `auto`
 * @param $dx the margin right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin hmargin($sx: auto, $dx: auto) {
  margin-left: $sx;
  margin-right: $dx;
}

/**
 * Add vertical margin to elements.
 *
 * @mixin
 * @param $top the margin top value. Default value is `auto`
 * @param $bottom the margin bottom value. Default value is `auto`
 * @example
 *  @include vmargin(3px);
 *  @include vmargin(3px, 4px);
 */
@mixin vmargin($top: auto, $bottom: auto) {
  margin-bottom: $bottom;
  margin-top: $top;
}

/**
 * Add horizontal padding to elements.
 *
 * @mixin
 * @param $sx the padding left value. Default value is `auto`
 * @param $dx the padding right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin hpadding($sx: auto, $dx: auto) {
  padding-left: $sx;
  padding-right: $dx;
}

/**
 * Add vertical padding to elements.
 *
 * @mixin
 * @param $top the margin left value. Default value is `auto`
 * @param $bottom the margin right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin vpadding($top: auto, $bottom: auto) {
  padding-bottom: $bottom;
  padding-top: $top;
}

/**
 * Add a font size css rule in px. Only for retrocompatibility.
 *
 * @mixin
 * @deprecated
 * @param $size the font size without unit
 * @example
 *  @include font-size(18);
 */
@mixin font-size($size) {
  font-size: $size + px;
}

/**
 * Set the size of an element.
 *
 * @mixin
 * @param $width element width in pixel
 * @param $height element height in pixel
 */
//sass-lint:disable no-important
@mixin set-size($width: 0, $height: 0) {
  height: $height !important;
  width: $width !important;
}
//sass-lint:enable no-important

/**
 * Set the position of an element.
 *
 * @mixin
 * @param $x offset from left
 * @param $y offset from top
 */
@mixin set-position($x: 0, $y: 0) {
  left: $x;
  top: $y;
}

/**
 * Place an absolute element.
 *
 * @mixin
 * @param $x offset from left
 * @param $y offset from top
 */
@mixin place-absolute($width: 0, $height: 0, $x: 0, $y: 0) {
  @include set-size($width, $height);
  @include set-position($x, $y);
  position: absolute;
}

// Private mixin

@mixin h-like($fontsize) {
  color: $black;
  font-family: $main-font;
  font-size: $fontsize + px;
  font-weight: bold;
  line-height: $line-height-headings;
  margin-bottom: $gutter / 2;
  word-wrap: break-word;
}

@mixin stylize-lists {
  ul,
  ol {
    font-family: $font-primary;
    margin-bottom: 48px;
    padding-left: 28px;
  }

  li {
    color: $dea-color-grey-dark;
    font-family: $font-primary;
    font-size: $font-size-large;
    line-height: 1.6;
    margin-bottom: $space-xx-small;
    padding-left: $space-x-small;
  }
}

//sass-lint:disable no-important no-qualifying-elements no-ids id-name-format
@mixin svg-fill-target($color) {
  svg {
    #baloon {
      fill: $color !important;
    }

    path.cls-n001 {
      // Shame - we need this to patch one of their icons
      fill: #fff;
    }
  }
}
//sass-lint:enable no-important no-qualifying-elements no-ids id-name-format

@mixin column-list($padding-left) {
  @include media("<s") {
    grid-template-columns: 1fr;
  }
  @include dea-heading-regular-m;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0;
  margin-left: 0 !important; //sass-lint:disable-line no-important
  margin-top: $space-x-small;
  padding-left: $padding-left !important; //sass-lint:disable-line no-important

  li {
    &:before {
      font-size: $h-font-size-m-large;
    }
    line-height: $line-height-heading !important; //sass-lint:disable-line no-important
    margin-bottom: $space-xx-small;
  }

  a {
    @include dea-heading-regular-xs;
    margin-left: $space-xs-small;
    text-decoration: underline;
  }
}
