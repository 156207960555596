.text-bg-yellow {
  @include font-size-large;
  background-color: #fecb00;
  color: $dea-color-grey-dark;
  display: inline-block;
  font-family: $font-primary;
  padding: 0 $space-xx-small;
}

.text-deaflix {
  @include dea-heading-regular-m;
  color: #e4005b;
}

.text-eaf {
  @include dea-heading-medium-m;
  color: #9b4c8e;
}

.list-deaflix {
  list-style-image: url("../img/deaflix_play.png") !important; //sass-lint:disable-line no-important
  margin-left: 0 !important; //sass-lint:disable-line no-important
  padding-left: $space-base !important; //sass-lint:disable-line no-important

  li {
    &:before {
      content: "" !important; //sass-lint:disable-line no-important
    }
  }
}

.list-column-pink-dot {
  @include column-list($space-x-small);

  li:before {
    color: $dea-color-target-ddi !important; //sass-lint:disable-line no-important
  }
}

.site-main {
  &--tdi {
    .wysiwyg a {
      border-bottom: $border-bold $dea-color-target-hover-tdi;
      text-decoration: none;
    }
  }
}

.text-underline-black {
  border-bottom: $border-bold $dea-color-grey-dark;
  display: inline-block;
}

.w-text__text-body {
  &.wysiwyg {
    hr {
      border: $border-light $dea-color-grey-medium;
    }
  }
}

.segmentation-link {
  width: fit-content;

  .dea-cross-link {
    text-decoration: none;
  }

  .dea-modal__content {
    .dea-links {
      text-decoration: none;

      &__label {
        font-family: $font-primary-medium;
      }
    }
  }
}

.small-text {
  font-size: $h-font-size-small !important; //sass-lint:disable-line no-important
}

.colored-flags-list {
  margin-left: $space-xm-small !important; //sass-lint:disable-line no-important
}

.colored-flags {
  margin-bottom: $space-m-small !important; //sass-lint:disable-line no-important
  padding-left: $space-large !important; //sass-lint:disable-line no-important
  position: relative;

  &:before {
    background-repeat: no-repeat;
    content: "" !important; //sass-lint:disable-line no-important
    height: $space-s-large;
    left: 0;
    margin-left: -$space-xm-small !important; //sass-lint:disable-line no-important
    position: absolute;
    top: $space-xxx-small;
    width: $space-s-large;
  }

  &--yellow:before {
    background-image: url("../img/flags/Dea_icona_flag_giallo.svg");
  }

  &--orange:before {
    background-image: url("../img/flags/Dea_icona_flag_arancio.svg");
  }

  &--red:before {
    background-image: url("../img/flags/Dea_icona_flag_rosso.svg");
  }

  &--green:before {
    background-image: url("../img/flags/Dea_icona_flag_verde.svg");
  }

  &--sky-blue:before {
    background-image: url("../img/flags/Dea_icona_flag_azzurro.svg");
  }

  &--purple:before {
    background-image: url("../img/flags/Dea_icona_flag_viola.svg");
  }
}
