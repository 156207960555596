$deaflix-logo-width: 138px;

@mixin site-header-single-page-logo($logo-width) {
  .logos-container .header--center__logo--fixed {
    width: $logo-width;
  }
}

@mixin discipline-item-color($color) {
  .w-discipline-item {
    &:hover,
    &.active {
      border-color: $color;
    }

    &__content {
      border: $border-bold $color;

      ul a {
        color: $color;
      }
    }
  }
}

@mixin tabbed-boxes-grid($element-number) {
  @include media("<l") {
    grid-row: #{ceil($element-number / 3) + 1} / #{ceil($element-number / 3) + 2};
  }
  @include media("<m") {
    grid-row: #{ceil($element-number / 2) + 1} / #{ceil($element-number / 1) + 3};
  }
  @include media("<sm") {
    grid-row: #{$element-number + 1} / #{$element-number + 2};
  }
  grid-row: #{ceil($element-number / 4) + 1} / #{ceil($element-number / 4) + 2};
}

@for $i from 1 through 48 {
  .w-discipline-item__content-number-#{$i} {
    @include tabbed-boxes-grid($i);
  }
}

.site-header--single-page-deaflix {
  @include site-header-single-page-logo($deaflix-logo-width);
}

.site-main--single-page-deaflix {
  @include discipline-item-color($dea-color-deaflix);
}

.site-main--single-page {
  section + section {
    margin-top: $space-large;
  }

  .page-header__claim {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include media("<sm") {
        @include dea-heading-medium-xxl;
      }
      @include dea-heading-medium-xxxl;
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
    }
  }

  .w-text {
    margin-bottom: $space-large;
  }
}

.w-enhanced-title--disciplines {
  @include dea-heading-regular-m;
}

.w-embedded-video {
  .w-embedded-video__title {
    margin-bottom: $space-xm-small;
  }

  .w-embedded-video__iframe-container {
    margin-top: 0;
  }
}

.disciplines {
  @include media("<l") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @include media("<m") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include media("<sm") {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  display: grid;
  grid-column-gap: $space-small;
  grid-row-gap: $space-small;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-bottom: $space-large;
}

.w-discipline-item {
  background: $dea-color-grey-light;
  border: $border-bold transparent;
  cursor: pointer;
  padding: $space-small;

  &__box {
    display: flex;
  }

  &__title {
    @include dea-heading-medium-m;
    margin-left: $space-xm-small;
    margin-top: $space-xx-small;
  }

  &__content {
    @include media("<l") {
      grid-column: 1 / 4;
    }
    @include media("<m") {
      grid-column: 1 / 3;
    }
    @include media("<sm") {
      grid-column: 1 / 2;
    }
    background: $dea-color-grey-light;
    display: none;
    grid-column: 1 / 5;
    padding: $space-m-small $space-small;

    &.active {
      display: block;
    }

    ul {
      @include column-list($space-base);
    }
  }
}
