.dea-lancio {
  &.dea-lancio--2-12,
  &.dea-lancio--3-12,
  &.dea-lancio--6-12,
  &.dea-lancio--7-12,
  &.dea-lancio--10-12,
  &.dea-lancio--12-12,
  &.dea-lancio--fullwidth {
    @include media("<sm") {
      flex-direction: column-reverse;

      .dea-lancio__inner {
        margin-bottom: auto;
      }
    }

    .dea-lancio__bg-image:not(.dea-lancio__bg-image--mobile-hide) {
      @include media("<m") {
        display: block;

        img {
          object-position: bottom right;
        }
      }

      @include media("<s") {
        height: 100%;
      }

      @include media("<sm") {
        height: auto;
        margin: $space-small auto 0 auto;
        position: relative;
        width: 50%;

        img {
          object-fit: cover;
          object-position: bottom;
          position: relative;
        }
      }

      @include media("<xs") {
        width: 100%;
      }
    }
  }

  &.dea-lancio--fullwidth {
    .dea-lancio__bg-image:not(.dea-lancio__bg-image--mobile-hide) {
      @include media("<sm") {
        bottom: 0;
        height: 70%;
        top: auto;
      }

      @media screen and (max-width: $bp-size-small-medium) {
        height: auto;
      }
    }
  }
}
