$hubscuola-card-bg: #f2f6f4;
$hubscuola-ink-black: #0e3444;
$hubscuola-shadow-black: #12455933;
$hubscuola-primary-grade-color: #ff8b38;
$hubscuola-ss1g-color: #ff688c;
$hubscuola-ss2g-color: #52acff;
// sass-lint:disable indentation
$hubscuola-fonts: "Figtree", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
// sass-lint:enable indentation

.w-dea-launchsection-plugin__launches.w-dea-launchsection-plugin__launches--grid {
  padding: 0;
}

.launches.launches--grid {
  @include media("<m") {
    grid-template-columns: 1fr 1fr;
  }
  @include media("<s") {
    grid-template-columns: 1fr;
  }

  display: grid;
  gap: $space-base; // sass-lint:disable-line no-misspelled-properties
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  margin-left: unset;
  width: 100% !important; // sass-lint:disable-line no-important

  .dea-lancio {
    margin-bottom: 0;
    width: 100% !important; // sass-lint:disable-line no-important
  }
}

.dea-lancio-parallax a {
  z-index: 1;
}

.category__detail {
  .w-dea-launchsection-plugin {
    @include media("<m") {
      .w-dea-launchsection-plugin__launches {
        padding: 0;
      }
    }

    padding: 0;
  }
}

.hubscuola-card-docenti {
  .wysiwyg + .hubscuola-card-docenti__list {
    margin-top: $space-small;
  }

  &__list {
    display: grid;
    gap: $space-small; // sass-lint:disable-line no-misspelled-properties
    grid-column: span 12 / span 12;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  &__card {
    @include media("<l") {
      grid-column: span 4 / span 4;
    }

    @include media("<m") {
      grid-column: span 6 / span 6;
    }

    @include media("<sm") {
      grid-column: span 12 / span 12;
    }
    background: $hubscuola-card-bg;
    border-radius: $space-small;
    box-shadow: 0 0 16px 4px $hubscuola-shadow-black;
    grid-column: span 3 / span 3;
    height: fit-content;
    margin-bottom: $space-small;
    overflow: hidden;
    position: relative;

    &:before {
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: $space-small;
    }

    &--primary-grade {
      &:before {
        background: $hubscuola-primary-grade-color;
      }

      .hubscuola-card-docenti__subtitle {
        &:before {
          background: $hubscuola-primary-grade-color;
        }
      }
    }

    &--first-grade {
      &:before {
        background: $hubscuola-ss1g-color;
      }

      .hubscuola-card-docenti__subtitle {
        &:before {
          background: $hubscuola-ss1g-color;
        }
      }
    }

    &--second-grade {
      &:before {
        background: $hubscuola-ss2g-color;
      }

      .hubscuola-card-docenti__subtitle {
        &:before {
          background: $hubscuola-ss2g-color;
        }
      }
    }
  }

  &__content {
    overflow: hidden;
    padding-left: $space-small;
    position: relative;
  }

  &__text {
    padding: 0 $space-small 0 $space-x-small;
  }

  &__title {
    color: $hubscuola-ink-black;
    font-family: $hubscuola-fonts;
    font-size: $h-font-size-large;
    font-weight: 800;
    margin: 0;
    margin-left: $space-small;
  }

  &__subtitle {
    color: $hubscuola-ink-black;
    font-family: $hubscuola-fonts;
    font-size: $h-font-size-xx-small;
    font-weight: $font-weight-bold;
    margin-bottom: $space-small;
    margin-top: 0;
    min-height: calc(#{$space-base} + #{$space-xx-small});
    opacity: 70%;
    padding-left: $space-small;
    padding-right: $space-x-small;
    position: relative;
    text-transform: uppercase;

    &:before {
      border-radius: 100%;
      content: "";
      display: block;
      height: $space-x-small;
      left: 0;
      position: absolute;
      top: $space-xxx-small;
      width: $space-x-small;
    }
  }

  &__image {
    margin-bottom: $space-base;
    max-width: 100%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__content {
    padding-bottom: $gutter-double;
  }
}
