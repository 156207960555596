$book-selector-cover-width: 65px;
$multi-dam-listing-sidebar-width: 380px;
$multi-dam-listing-sidebar-offset-large: 135px;
$multi-dam-listing-sidebar-offset-medium: 75px;

.multi-dam-listing {
  width: 100%;

  .grid {
    margin-left: 0;
    width: 100% !important; // sass-lint:disable-line no-important
  }

  &__sidebar {
    @include media("<m") {
      padding-left: $gutter-half;
    }

    @include media("<=s") {
      display: none;
      padding-right: $gutter-half;
    }

    &-toggle {
      margin-bottom: $space-base;

      &.dea-button .dea-button__label {
        @include dea-heading-medium-m;
      }

      &-container {
        @include media(">s") {
          display: none;
        }
        @include hpadding($gutter-half, $gutter-half);
      }

      .dea-button__icon {
        &.active {
          transform: rotate(180deg);
        }

        transform: rotate(0deg);
        transition: transform 0.5s;
      }
    }

    &--fixed {
      @include media(">=m") {
        top: $multi-dam-listing-sidebar-offset-large;
      }

      @include media("<m") {
        top: $multi-dam-listing-sidebar-offset-medium;
      }

      overflow: hidden;
      position: sticky;

      .multi-dam-listing__book-listing {
        max-height: calc(100vh - #{$multi-dam-listing-sidebar-width});
        overflow-y: scroll;
      }

      &-bottom {
        margin-bottom: $gutter;
      }
    }

    &-item {
      @include dea-heading-regular-l;
      cursor: pointer;
      display: block;
      margin-top: 0;

      &.active {
        @include dea-heading-medium-l;
        border-left: $border-bold $dea-color-target-pink-zmus;
        padding-left: $space-xm-small;
      }
    }
  }

  &__content {
    @include media(">s") {
      border-left: $border-bold $dea-color-grey-light;
    }

    padding: $space-small 0;

    > * {
      display: none;
    }

    .w-dea-resources__title {
      @include dea-heading-medium-xl;
      margin-top: 0;
    }
  }
}

.book-selector {
  margin-bottom: $space-intermediate;

  &-list {
    border-bottom: $border-bold $dea-color-grey-light;
    border-top: $border-bold $dea-color-grey-light;
    padding: $h-font-size-xx-small 0;
  }

  &-info {
    display: flex;
    flex-direction: column;
    margin-left: $space-ml-small;
    width: 100%;

    &--label {
      @include dea-heading-regular-xxs;
      @include vmargin(0, $space-xs-small);
    }

    &--title {
      @include dea-heading-medium-s;
      @include vmargin(0, 0);
    }

    &--subtitle {
      @include dea-heading-light-s;
      @include vmargin(0, $space-xs-small);
    }

    &--link {
      display: block;
      margin-top: auto;
      text-align: right;
      width: 100%;

      span {
        @include dea-heading-regular-xxs;
        border-bottom: $border-bold $dea-color-target-pink-zmus;
      }
    }
  }

  &-book-container {
    display: flex;
    padding: $space-x-small;
  }

  &-book-other {
    background-color: $dea-color-grey-light;
    margin-bottom: $space-small;

    &.hidden {
      display: none;
    }
  }

  &-cover {
    align-self: flex-start;
    height: auto;
    max-width: $book-selector-cover-width;
  }

  &-label {
    @include dea-heading-regular-xxs;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .dea-links__icon {
      font-size: $h-font-size-large;
    }
  }
}
