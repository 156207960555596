$dea-page-header-x-height: 193px;
$dea-page-header-ross-height: 183px;
$dea-hero-box-eaf-height: 337px;
$dea-hero-box-eaf-mobile-height: 369px;

.educazione-civica-titles {
  @include media("<=s") {
    margin-left: $space-base;
  }

  @include media("<=xs") {
    margin-left: $space-small;
  }
}

.tpl-minisite {
  .wrapper-page-header--height-t {
    max-width: $screen-l;

    .page-header__claim {
      @include media(">=m") {
        margin-left: 15%;
      }
    }
  }

  .page-header__container h1,
  .page-header__container h2,
  .page-header__container h3,
  .page-header__container h4 {
    font-family: $font-primary-medium;
    font-weight: $font-weight-regular;
  }

  .dea-hero__navigation-button-container {
    @include media("<l") {
      width: 100%;
    }
    margin-top: -$dea-size-x-large;
    width: $grid-normal;
  }

  .w-section__header h2,
  .dea-search__title {
    @extend .dea-heading--medium__l;
    color: $dea-color-black;
  }

  .mobile-menu {
    @include media(">m", "<l") {
      display: none;
    }
  }
}

// Ported from Zona Musica
.site-main--ec {
  .page-header {
    @include media("<s") {
      padding: 0;
    }

    .page-header__container-with-min-height {
      &.page-header__container--height-s {
        @include media("<s") {
          margin: 0;
        }
        min-height: $dea-page-header-x-height;
      }

      .page-header__claim {
        @include media("<m") {
          align-items: flex-start;
          height: 100%;
          padding-right: 40%;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
          @include dea-heading-medium-xxxl;
          margin: 0;
          text-align: left;
        }

        p {
          @include media("<m") {
            text-align: left;
          }
          margin-bottom: 0;
          margin-top: $space-m-small;
        }
      }
    }
  }
}

.site-header {
  &--ec,
  &--ddi,
  &--eds,
  &--tdi,
  &--ross,
  &--eaf {
    .mobile-menu__content {
      @include hpadding($space-base);
    }

    .mobile-menu__close {
      top: $space-s-large;
    }

    .mobile-menu__back {
      top: 45px;
    }
  }
}

.site-main--ross {
  .page-header--no-image-background {
    .page-header__container--height-s {
      height: $dea-page-header-ross-height;
    }
  }

  .w-enhanced-title {
    @include dea-heading-medium-m;
  }

  .w-dea-launchsection-plugin {
    @include media("<=sm") {
      width: 100%;
    }

    margin: 0 auto;
    width: 85%;
  }

  .wysiwyg {
    * {
      @include dea-heading-light-m;
    }

    .dea-cross-link__button.white-button {
      border-color: $dea-color-grey-dark;
      min-width: auto;
    }

    .dea-cross-link__label {
      @include dea-heading-medium-xs;
    }

    li:before {
      @include dea-heading-regular-xl;
      line-height: 1;
    }
  }

  .w-ross-sgrammanebbia-score {
    margin-bottom: $space-x-large;

    &__button {
      @include media("<=s") {
        margin: $space-base auto;
        text-align: center;
      }
    }

    &__score {
      margin-left: $space-s-large;
      margin-top: $space-small;

      * {
        text-transform: uppercase;
      }

      p {
        margin-bottom: $space-xx-small;
      }
    }

    &__levels-score {
      margin-left: $space-small;
    }
  }
}

.aed-roles-segmentation-plugin {
  .wysiwyg {
    margin-bottom: $space-base;
  }

  margin: $space-x-large 0;
}

.w-text {
  &.small-margin {
    margin-bottom: $space-base;
  }

  &.extra-small-margin {
    margin-bottom: $space-xm-small;
  }
}

$dea-fic-page-header-height-desktop: 356px;
$dea-fic-page-header-height-mobile: 258px;
$dea-fic-page-header-bottom-stripe-height: 13px;
$dea-fic-page-header-margin-bottom-desktop: 93px;
$dea-fic-page-header-margin-bottom-mobile: 45px;
$dea-fic-live-card-image-height: 158px;
$dea-fic-card-image-height: 145px;
$dea-fic-launch-image-mobile-height: 218px;
$dea-fic-launch-logo-mobile-size: 55px;

$dea-fic-page-header-bottom-stripe-height-first-color: #007166;
$dea-fic-page-header-bottom-stripe-height-second-color: #70bfd6;
$dea-fic-page-header-bottom-stripe-height-third-color: #9b4a8d;
$dea-fic-page-header-bottom-stripe-height-fourth-color: #de1d1b;
$dea-fic-page-header-bottom-stripe-height-fifth-color: #ef7e26;
$dea-fic-page-header-bottom-stripe-height-sixth-color: #f4ba0a;
$dea-text-grey: #333;
$dea-text-blue: #184052;
$dea-fic-card-box-shadow: 0 4px 4px 0 #00000040;

$h120-font-size: 20px;
$h120-line-height: 50px;
$h140-font-size: 40px;
$h140-line-height: 50px;
$h140-line-height-mobile: 40px;
$h2-font-size: 30px;
$h2-line-height: 36px;
$h2-line-height-mobile: 30px;
$body14-font-size: 14px;
$body14-line-height: 16px;
$body16-font-size: 16px;
$body16-line-height: 19px;
$body24-font-size: 24px;
$body24-line-height: 28px;
$font-weight-800: 800;

$border-radius-8: 8px;
$space-10px: 10px;
$space-56px: 56px;
$space-80px: 80px;
$space-100px: 100px;
$screen-540px: 540px;

.site-main {
  &.site-main--fic {
    &.site-main--home {
      @include media("<m") {
        padding-top: $space-large;
      }

      padding-top: $space-80px;

      section {
        &:first-of-type {
          .w-text {
            margin-bottom: 0;

            &__header > * {
              margin-bottom: $space-base;
            }
          }
        }
      }
    }

    .grid {
      padding-left: $space-xm-small;
      padding-right: $space-xm-small;
    }

    .page-header {
      &--no-image-background {
        @include media("<=s") {
          margin-bottom: $dea-fic-page-header-margin-bottom-mobile;
        }
        margin-bottom: $dea-fic-page-header-margin-bottom-desktop;

        &:after {
          background-image: linear-gradient(
            to right,
            $dea-fic-page-header-bottom-stripe-height-first-color 0% 16.67%,
            $dea-fic-page-header-bottom-stripe-height-second-color 16.67% 33.33%,
            $dea-fic-page-header-bottom-stripe-height-third-color 33.33% 50%,
            $dea-fic-page-header-bottom-stripe-height-fourth-color 50% 66.67%,
            $dea-fic-page-header-bottom-stripe-height-fifth-color 66.67% 83.33%,
            $dea-fic-page-header-bottom-stripe-height-sixth-color 83.33% 100%
          );
          content: "";
          display: block;
          height: $dea-fic-page-header-bottom-stripe-height;
          position: absolute;
          width: 100%;
        }
      }

      &__container {
        @include media("<m") {
          text-align: left;
        }

        &--height-x {
          @include media("<=s") {
            height: $dea-fic-page-header-height-mobile;
          }

          height: $dea-fic-page-header-height-desktop;

          .page-header__background-image {
            @include media("<m") {
              display: block;
            }
            @include media("<=s") {
              display: none;
            }

            margin-left: 10%;

            img {
              object-fit: cover;
              object-position: center;
            }

            &--mobile {
              @include media(">s") {
                display: none;
              }

              height: 100%;
              position: absolute;
              right: -$space-base;
            }
          }
        }
      }

      &__claim {
        @include media("<=s") {
          padding: 0;
        }

        > * {
          font-family: "LatoRegular";
        }

        h1,
        h2 {
          @include media("<=s") {
            line-height: $h140-line-height-mobile;
          }

          font-size: $h140-font-size;
          font-weight: $font-weight-bold;
          line-height: $h140-line-height;
        }
      }
    }

    .w-section {
      margin-top: 0;

      &--with-background {
        @include media("<=s") {
          padding-bottom: $space-x-large;
        }

        padding-bottom: $space-80px;
        padding-top: $space-intermediate;

        .w-section__header {
          margin-bottom: 0;
          padding-left: $space-xm-small;
          padding-right: $space-xm-small;

          > * {
            @include media("<=s") {
              line-height: $h2-line-height-mobile;
            }

            font-family: "LatoRegular";
            font-size: $h2-font-size;
            font-weight: $font-weight-bold;
            line-height: $h2-line-height;
            margin: 0;
          }
        }

        &.w-section--big-margins {
          @include media("<=s") {
            padding-bottom: $space-x-large;
            padding-top: $space-large;
          }
          padding-bottom: $space-100px;
          padding-top: $space-80px;
        }
      }

      &--white-text {
        .w-section__header {
          @include media("<=s") {
            margin-bottom: $space-base;
          }

          margin-bottom: $space-intermediate;

          > * {
            color: $dea-color-white;
          }
        }
      }
    }

    .w-text {
      margin-bottom: $space-s-large;

      h1,
      h2,
      h3,
      p,
      a,
      li {
        font-family: "LatoRegular";
      }

      &__header {
        h1,
        h2 {
          @include media("<=s") {
            line-height: $h2-line-height-mobile;
          }

          font-size: $h2-font-size;
          font-weight: $font-weight-bold;
          line-height: $h2-line-height;
        }

        > * {
          color: $dea-color-black;
          margin-bottom: $space-x-small;
        }
      }

      &__text-body {
        > * {
          color: $dea-text-grey;
          margin-bottom: 0;

          & + * {
            margin-top: $space-small;
          }
        }

        p,
        a,
        li {
          font-size: $body16-font-size;
          line-height: $body16-line-height;
        }
      }

      &--read-more {
        margin-bottom: 0;

        h1,
        h2,
        h3,
        p,
        a,
        li {
          color: $dea-color-white;
        }

        a {
          font-weight: $font-weight-bold;
        }

        .dea-links--show-more {
          text-decoration: none;

          .dea-links__label,
          .dea-links__icon {
            border-bottom: 0;
            border-top: 0 !important; // sass-lint:disable-line no-important
            color: $dea-color-white;
            font-family: "LatoRegular";
            font-size: $body16-font-size;
            line-height: $body16-line-height;
          }

          .dea-links__label {
            font-weight: $font-weight-bold;
            margin-right: $space-xx-small;
            text-decoration: underline;
          }

          .dea-links__icon {
            margin-top: $space-xs-small;

            &:before {
              font-weight: $font-weight-bold !important; // sass-lint:disable-line no-important
            }
          }
        }

        .w-text {
          &__header > * {
            margin-bottom: $space-base;
          }

          &__text-body > * {
            margin-bottom: 0;

            & + * {
              margin-top: $space-base;
            }
          }

          &__show-more-text > * {
            margin-bottom: 0;

            & + * {
              margin-top: $space-base;
            }
          }
        }
      }
    }

    .background-container {
      @include media("<=s") {
        padding: $space-intermediate $space-base;
      }

      border-radius: $border-radius-8;
      margin-bottom: 0;
      margin-left: $space-xm-small;
      margin-right: $space-xm-small;
      padding: $space-s-large $space-x-large;

      .grid {
        margin: 0;
        padding: 0;
        width: 100%;

        > * {
          margin-bottom: 0;
          padding: 0;

          & + * {
            margin-top: $space-intermediate;
          }
        }
      }

      & + .background-container {
        margin-top: $space-base;
      }

      .w-text {
        margin-bottom: 0;

        &__header {
          h3 {
            font-size: $body24-font-size;
            font-weight: $font-weight-bold;
            line-height: $body24-line-height;
            margin-bottom: $space-small;
          }

          > * {
            color: $dea-text-blue;
          }
        }

        &__text-body {
          > * {
            color: $dea-text-blue;

            li {
              color: $dea-text-blue;

              &:before {
                color: $dea-text-blue;
              }
            }
          }
        }
      }

      .w-card {
        &--fic {
          margin-bottom: 0;
        }

        &__container {
          justify-content: space-between;
        }

        &__title-text-link-container {
          @include media("<=s") {
            width: 100%;
          }

          width: 75%;
        }

        &__logo-container {
          @include media("<=s") {
            display: none;
          }

          display: flex;
          justify-content: flex-end;
          width: 15%;
        }

        &__title-container {
          margin-bottom: $space-small;
          width: 100%;

          header {
            margin-bottom: 0;
          }

          h2,
          h3 {
            color: $dea-text-blue;
            font-family: "LatoRegular";
            font-size: $body24-font-size;
            font-weight: $font-weight-bold;
            line-height: $body24-line-height;
            margin-bottom: 0;
          }
        }

        &__text-container {
          margin-bottom: $space-intermediate;
        }

        &__text {
          margin-bottom: 0;

          &-body > * {
            color: $dea-text-blue;
            font-family: "LatoRegular";
            font-size: $body16-font-size;
            line-height: $body16-line-height;
            margin-bottom: 0;

            & + * {
              margin-top: $space-small;
            }

            li {
              color: $dea-text-blue;
              font-family: "LatoRegular";
              font-size: $body16-font-size;
              line-height: $body16-line-height;

              &:before {
                color: $dea-text-blue;
              }
            }
          }
        }

        &__logo {
          position: initial;
          transform: none;
          width: 100%;
        }
      }

      .dea-feed {
        width: 100%;

        &__header {
          display: none;
        }

        .dea-tabs-contents {
          margin-top: 0;

          .grid {
            @include media("<=s") {
              grid-row-gap: $space-base;
              grid-template-columns: 1fr;
            }

            display: grid;
            grid-column-gap: $space-intermediate;
            grid-template-columns: repeat(3, minmax(0, 1fr));

            > * {
              margin-top: 0;
              width: 100%;
            }
          }
        }

        .dea-live-card {
          border-radius: $border-radius-8;
          box-shadow: $dea-fic-card-box-shadow;
          margin-bottom: 0;

          &__image img {
            height: $dea-fic-live-card-image-height;
          }

          &__title {
            @include media("<=s") {
              font-size: $body16-font-size;
              line-height: $body16-line-height;
            }

            color: $dea-text-grey;
            font-family: "LatoRegular";
            font-size: $body14-font-size;
            line-height: $body14-font-size;
            min-height: auto;
            text-align: center;

            &-container {
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: $space-base $space-intermediate $space-s-large $space-intermediate;
            }
          }

          .dea-links {
            margin-top: auto;
            text-decoration: underline;

            &__label {
              @include media("<=s") {
                font-weight: $font-weight-regular;
              }

              border: 0;
              color: $dea-color-black;
              font-family: "LatoRegular";
              font-size: $body16-font-size;
              font-weight: $font-weight-bold;
              line-height: $body16-line-height;
            }

            &__icon {
              display: none;
            }
          }
        }
      }
    }

    .w-card-container {
      @include media("<=s") {
        margin-bottom: $space-x-large;
        margin-top: $space-s-large;
      }

      &--big-margins {
        @include media("<m") {
          margin-top: $space-x-large;
        }
      }

      margin: $space-80px 0;

      &__text-container {
        margin-bottom: $space-base;

        > * {
          font-family: "LatoRegular";
          font-size: $h2-font-size;
          font-weight: $font-weight-bold;
          line-height: $h2-line-height;
          margin: 0;
        }
      }

      &__subtitle {
        margin-top: $space-x-small;

        > * {
          color: $dea-text-grey;
          font-family: "LatoRegular";
          font-size: $body16-font-size;
          line-height: $body16-line-height;
          margin: 0;
        }
      }

      &__container {
        @include media("<=s") {
          grid-gap: $space-base;
          grid-template-columns: 1fr;
        }

        display: grid;
        grid-gap: $space-intermediate;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    .w-card-box {
      border-radius: $border-radius-8;
      box-shadow: $dea-fic-card-box-shadow;
      display: flex;
      flex-direction: column;

      &__image img {
        border-radius: $border-radius-8 $border-radius-8 0 0;
        height: $dea-fic-card-image-height;
        object-fit: cover;
        width: 100%;
      }

      &__title {
        font-family: "LatoRegular";
        font-size: $body24-font-size;
        font-weight: $font-weight-bold;
        line-height: $body24-line-height;
        margin: 0;

        &-container {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          justify-content: space-between;
          padding: $space-base $space-intermediate;
          text-align: center;

          &--big-padding {
            padding: $space-base $space-s-large $space-s-large $space-s-large;
          }
        }
      }

      &__subtitle {
        font-family: "LatoRegular";
        font-size: $body14-font-size;
        line-height: $body14-line-height;
        margin-bottom: 0;
        margin-top: $space-x-small;

        &--big-margin {
          margin-top: $space-small;
        }
      }

      &__link {
        font-family: "LatoRegular";
        font-size: $body14-font-size;
        font-weight: $font-weight-bold;
        line-height: $body14-font-size;
        margin-top: $space-small;
        text-decoration: underline;
      }
    }

    .w-text-video {
      &__text-video-container {
        @include media("<m") {
          grid-template-columns: 1fr;
        }

        display: grid;
        grid-column-gap: $space-s-large;
        grid-template-columns: 6fr 5fr;
      }

      &__text-container {
        @include media("<m") {
          margin-top: $space-intermediate;
          order: 1;
        }

        h2,
        h3 {
          font-family: "LatoRegular";
          font-size: $h2-font-size;
          font-weight: $font-weight-bold;
          line-height: $h2-line-height;
          margin: 0;
        }

        .dea-cross-link__button--blue {
          margin-top: $space-base;
        }
      }

      &__body {
        margin-top: $space-base;

        > * {
          color: $dea-text-grey;
          font-family: "LatoRegular";
          font-size: $body16-font-size;
          line-height: $body16-line-height;
          margin: 0;
        }
      }

      &__video-container {
        @include media("<m") {
          height: 0;
          order: 0;
          padding-bottom: 56.25%;
        }
        height: 100%;
        position: relative;

        iframe,
        video {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}

.site-header {
  &.site-header--fic {
    .dea-main-header__main-title {
      @include media("<l") {
        font-size: $h120-font-size;
        line-height: $h120-line-height;
      }

      color: $dea-text-blue;
      font-family: "LatoRegular";
      font-size: $h140-font-size;
      font-weight: $font-weight-bold;
      line-height: $h140-line-height;
      text-transform: uppercase;
    }
  }
}

.site-header {
  &.site-header--fic,
  &.site-header--mus {
    @include media("<=m") {
      display: none;
    }
  }
}
