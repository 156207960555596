$assistance-target-image-h: 110px;
$contact-form-max-width-desktop: 500px;
$contact-form-fields-max-width: 360px;

.site-header--assistenza .header--center {
  border-bottom: $border-light;
}

.faq {
  margin-top: $space-base;

  &__categories {
    border-right: $border-light $dea-color-grey-medium-light;

    &-list {
      @include dea-heading-medium-l;
      list-style-type: none;
      margin-top: $space-xm-small;
      padding-left: 0;

      li {
        border-left: $border-bold-plus transparent;
        margin: 0 0 $space-xm-small 0;
        padding-left: $space-small;

        &.active,
        &:hover {
          border-color: $dea-color-secondary-magenta;
        }
      }
    }
  }

  &__articles {
    &-list {
      @include media("<=s") {
        padding-left: 0;
      }
      @include dea-heading-regular-s;

      list-style-type: none;

      hr {
        border-bottom: $border-light $dea-color-grey-medium-light;
        margin: $space-m-small 0;
        width: $space-x-large;
      }

      .subcategory-title {
        @include dea-heading-medium-l;
        @include vmargin($space-large, $space-intermediate);
      }

      .faq__articles-list {
        padding-left: 0;
      }
    }
  }

  &__article {
    &-item {
      border: $border-light $dea-color-grey-medium-light;
      border-radius: $border-radius-small;
      margin-bottom: $space-base;
      padding: $space-xx-small + $space-xxx-small;
    }

    &-item-title {
      @include dea-heading-medium-l;
      @include vmargin($space-x-small, $space-xm-small);
    }

    &-item-image {
      height: auto;
      max-width: 100%;
    }
  }

  .search-container {
    @include media("<=s") {
      @include vmargin($space-x-large, $space-large);
    }
    @include vmargin($space-xx-large, $space-x-large);

    .dea-header--center__search-container {
      @include media("<=s") {
        margin-left: 0;
        width: 100%;
      }

      width: 50%;

      &:before {
        transform: translate(2px);
      }
    }

    .articles-search-box {
      &__input {
        @include media("<=s") {
          padding-left: $space-intermediate;
        }
        transform: translateX(-$space-base);
      }

      &__results {
        @include dea-heading-regular-s;
        border: $border-light $dea-color-black;
        border-top: 0;
        list-style-type: none;
        margin: 0 0 0 $space-intermediate;
        padding: $space-small $space-base;

        &-container {
          margin-top: -$space-small;
        }
      }
    }
  }
}

.contact-form__container {
  &.contact-form__assistance {
    @include media("<=xs") {
      padding: $space-x-small $space-x-small $space-xx-large $space-x-small;
    }
  }

  .contact-form__form {
    max-width: $contact-form-max-width-desktop;

    .form-field {
      &:not(.form-field--user_type) {
        max-width: $contact-form-fields-max-width;
      }

      // sass-lint:disable class-name-format
      &--user_type {
        flex-wrap: wrap;
        justify-content: flex-start;

        .dea-checkbox {
          @include media("<=s") {
            margin-bottom: $space-base;
          }
          margin-bottom: $space-intermediate;
          margin-right: $space-base;

          &__label {
            vertical-align: sub;
          }
        }
      }

      &--request_type {
        margin-bottom: $space-s-large;
      }
      // sass-lint:enable class-name-format
    }
  }
}

.assistance {
  &__target-container {
    @include media("<=m") {
      flex-direction: column;
    }

    display: flex;
    justify-content: space-evenly;
  }

  &__target {
    @include media("<l") {
      padding: $space-base;
    }

    @include media("<=xs") {
      padding: 0;
    }

    padding: $space-large;

    &-link {
      @include media("<l") {
        justify-content: space-around;
      }

      align-content: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      &-title {
        text-align: center;
        width: 100%;
      }
    }

    &--left {
      @include media("<=m") {
        padding-right: $space-base;
      }

      @include media("<=xs") {
        padding-right: 0;
      }

      padding-right: $space-x-large;

      .assistance__target-link {
        @include media("<=m") {
          flex-direction: row;
        }

        flex-direction: column;
      }
    }

    &--right {
      @include media("<=m") {
        border-left: 0;
      }

      border-left: $border-light;
    }
  }

  &__target-image {
    max-height: $assistance-target-image-h;
  }
}

.contact-form--agency,
.contact-form--work {
  padding-top: $dea-header-height;

  .dea-links__label {
    border: 0;
    font-family: $font-primary;
    text-decoration: underline;
  }

  .dea-button {
    &:focus,
    &:focus-within {
      outline: auto;
    }
  }
}

.contact-form-sidebar {
  padding: $space-large $space-base !important; //sass-lint:disable-line no-important
}

.contact-form-sidebar {
  padding: $space-large $space-base !important; //sass-lint:disable-line no-important
}
