.work-evaluation {
  &-copy {
    &__list {
      list-style: none;
      padding: 0;
    }

    &__list-item {
      .dea-configuration-cover {
        display: flex;
        gap: $gutter; // sass-lint:disable-line no-misspelled-properties
        justify-items: flex-start;
        margin-bottom: $gutter-half;
        padding: $gutter-half 0 $gutter;

        .dea-configuration-data {
          padding: 0;

          &__label {
            color: $dea-color-grey-medium;
            text-transform: none;
          }
        }

        .dea-configuration-data__meta-single {
          margin: $gutter 0 0;
        }
      }

      // sass-lint:disable no-qualifying-elements
      .dea-checkbox {
        input[type="checkbox"] {
          visibility: visible;
        }

        &:focus,
        &:focus-within {
          outline: none;

          .dea-checkbox__label-wrapper {
            &:before {
              outline: auto;
            }

            &:after {
              outline: none;
            }
          }
        }
      }
      // sass-lint:enable no-qualifying-elements

      .dea-checkbox__label-wrapper {
        &:before {
          position: absolute;
          top: 0;
        }

        &:after {
          position: absolute;
          top: $gutter/6;
        }
      }
    }
  }
}

.dea-button__icon {
  &--svg-include {
    margin-right: $space-x-small;
    width: $dea-button-size-extra-small;

    svg {
      display: block;
      width: $dea-button-size-extra-small;
    }
  }
}

.dea-modal-close--active {
  &:focus,
  &:focus-within {
    outline: auto;
  }
}
