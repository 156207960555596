$hero-big-image-height: 480px;
$hero-big-image-tablet-height: 398px;
$hero-wide-big-image-height: 337px;
$hero-wide-big-image-mobile-height: 520px;
$hero-big-image-width: 936px;
$hero-small-image-height: 151px;
$hero-small-image-tablet-height: 124px;
$hero-small-bottom-image-tablet-height: 108px;
$hero-small-image-tablet-width: 189px;
$hero-image-mobile-height: 168px;
$hero-big-text-width: 264px;
$hero-big-text-tablet-width: 296px;
$hero-big-text-height: 350px;
$hero-big-text-tablet-height: 274px;
$hero-first-box-color: #7d2991;
$hero-second-box-color: #009985;
$hero-third-box-color: #cb007b;
$hero-fourth-box-color: #f68330;
$hero-first-box-border: 7px;
$hero-box-shadow-box-large: 8px 8px 24px 0 rgba(#000, 0.1);
$hero-box-shadow-box-small: 0 6px 24px 0 rgba(#000, 0.1);
$hero-bottom-box-width: calc(50% - #{$gutter-half});
$hero-box-buttons-width: 306px;
$text-logo-link-box-border-radius: 7px;
$text-logo-link-box-box-shadow: 2px 4px 8px 0 rgba(#000, 0.1);
$text-logo-link-box-formazione-box-shadow: 2px 2px 20px 0 rgba(#cdcdcd, 0.5);
$text-logo-link-box-learning-min-width-mobile: 168px;
$text-logo-link-box-video-min-width-mobile: 263px;
$text-logo-link-box-formazione-image-height: 221px;
$text-logo-link-box-formazione-image-height-tablet: 190px;
$text-logo-link-box-dlive-logo-width: 85px;
$text-logo-link-box-dlive-logo-width-table: 110px;
$text-logo-link-box-dlive-latest-image-height: 140px;
$text-logo-link-box-dlive-latest-logo-width: 66px;
$text-logo-link-box-dlive-latest-mobile-border-radius: 11px;
$text-logo-link-box-dlive-latest-mobile-box-shadow: 2px 4px 8px 0 rgba(#000, 0.18);
$text-logo-link-box-video-image-height: 150px;
$catalog-box-height: 175px;
$catalog-box-height-mobile: 283px;
$catalog-box-border-color: #979797;
$catalog-box-border-radius: 14px;
$catalog-box-search-border-radius: 21px;
$catalog-hero-height: 260px;
$catalog-filter-svg-height: 43px;
$catalog-filter-button-width: 360px;

// FIXME
// These styles are partially ported from other projects and partially modified for a correct layout.
// We should consider if port this to every project (on storybook) or leave them here.

.wrapper-content {
  @include media("<m") {
    max-width: 100%;
  }

  .grid {
    width: 100%;

    .w-dea-launchsection-plugin {
      @include hpadding(0, 0);

      &__title {
        margin-bottom: $space-m-small;
        text-align: center;
      }

      &__launches {
        @include media("<m") {
          @include hpadding($space-base, $space-base);

          .dea-lancio__cta-container {
            text-align: left;
          }
        }
      }
    }
  }
}

.portals.portals-plugin {
  @include hpadding($space-xm-small, $space-xm-small);
  margin-bottom: 0;

  .aed__list {
    @include media("<m") {
      @include hpadding($space-xm-small, $space-xm-small);
      grid-template-columns: repeat(2, 1fr);
    }
    @include media("<=s") {
      grid-template-columns: 1fr;
    }
    @include hpadding(0, 0);
    display: grid;
    grid-gap: $space-base;
    grid-template-columns: repeat(3, 1fr);

    &-item {
      margin-bottom: 0;

      .dea-card-service {
        border: $border-light $dea-color-grey-medium-light;
        border-radius: $border-radius-large;
        margin-bottom: 0;
      }
    }
  }
}

.site-main {
  &.site-main--catalog {
    .page-header {
      @include media("<s") {
        padding-top: $space-x-large;
      }

      margin-bottom: $space-ml-small;

      &__claim {
        display: none;
      }

      &__cta {
        display: none;
      }

      &__container--height-s {
        @include media("<m") {
          @include hpadding($space-xm-small, $space-xm-small);
          display: flex;
        }
        align-items: center;
        justify-content: center;
        min-height: $catalog-hero-height;
      }

      @include stylize-search-form(
        0,
        $space-intermediate,
        $space-m-small,
        $space-xxx-small,
        $space-m-small,
        $space-xx-small,
        $catalog-hero-input-line-height,
        $catalog-hero-input-border-radius
      );
    }
  }
}

.page-home {
  .site-header + .main-content {
    @include media("<=m") {
      margin-top: 0;
    }
  }
}
// END FIXME

@mixin stylize-first-hero-box($image-width) {
  @include media("<=s") {
    box-shadow: $hero-box-shadow-box-large;
  }
  display: flex;
  grid-area: second-box;
  position: relative;
  width: 100%;

  a {
    position: relative;
    width: 100%;
  }

  .hero-box__image {
    @include media("<=m") {
      height: $hero-big-image-tablet-height;
    }
    @include media("<=s") {
      height: 0;
      padding-bottom: 50%;
    }
    background-position: center;
    background-size: cover;
    border-radius: $border-radius-medium;
    height: $hero-big-image-height;
    max-width: 100%;
    width: $image-width;
  }

  .hero-box__separator {
    background-color: $hero-first-box-color;
  }

  .hero-box__text {
    &-container {
      @include media("<=m") {
        @include hpadding($space-large, $space-large);
        height: $hero-big-text-tablet-height;
        width: $hero-big-text-tablet-width;
      }
      @include media("<=s") {
        @include hpadding($space-small, $space-small);
        @include vpadding($space-m-small, $space-m-small);
        box-shadow: none;
        height: auto;
        position: inherit;
        width: 100%;
      }
      @include hpadding($space-s-large, $space-base);
      background-color: $dea-color-white;
      border-bottom: $hero-first-box-border solid $hero-first-box-color;
      border-radius: $border-radius-medium;
      bottom: 0;
      box-shadow: $hero-box-shadow-box-large;
      display: flex;
      flex-direction: column;
      height: $hero-big-text-height;
      justify-content: center;
      margin: auto 0;
      position: absolute;
      right: 0;
      top: 0;
      width: $hero-big-text-width;
    }
  }

  .hero-box__title {
    @include media("<=m") {
      @include dea-heading-medium-ml;
    }
    @include media("<=s") {
      @include vmargin(0, 0);
    }
    @include dea-heading-medium-xl;
    @include vmargin($space-base, $space-m-small);
    line-height: $line-height-small;
  }

  &.hero-box--wide {
    @include media("<=s") {
      @include hmargin(-$space-base, -$space-base);
      @include vmargin(0, $space-base);
      box-shadow: none;
      flex-direction: column;
      width: calc(100% + #{$space-base} * 2);
    }

    .hero-box__image {
      @include media("<=s") {
        height: $hero-wide-big-image-mobile-height;
        padding-bottom: 0;
      }
      background-position: center;
      background-size: cover;
      border-radius: 0;
      height: $hero-wide-big-image-height;
      max-width: 100%;
      width: 100%;
    }

    .hero-box__text {
      &-container {
        @include media("<=m") {
          width: 60%;
        }
        @include media("<m") {
          @include hpadding($space-s-large, 0);
          @include vpadding($space-intermediate, $space-intermediate);
        }
        @include media("<=s") {
          box-shadow: none;
          height: auto;
          padding: $space-intermediate $space-base;
          width: 100%;
        }
        @include hpadding($space-large, 0);
        @include vpadding($space-s-large, $space-s-large);
        background-color: transparent;
        border: 0;
        border-radius: 0;
        bottom: 0;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: flex-start;
        left: 0;
        margin: auto 0;
        position: absolute;
        top: 0;
        width: 50%;
      }
    }

    .hero-box__title {
      @include media("<m") {
        @include dea-heading-medium-xxxl;
        @include vmargin(0, $space-base);
      }
      @include media("<=s") {
        @include vmargin(0, 0);
      }
      @include dea-heading-medium-xxxl;
      @include vmargin(0, $space-s-large);
      line-height: $line-height-small;

      &--white {
        color: $dea-color-white;
      }
    }

    .hero-box__description {
      @include media("<=s") {
        margin-top: $space-base;
      }

      * {
        @include dea-heading-regular-m;
        @include vmargin(0, 0);
      }

      &--white {
        * {
          color: $dea-color-white;
        }
      }
    }

    .hero-box__buttons {
      @include media("<=m") {
        width: 40%;
      }
      @include media("<=s") {
        bottom: 0;
        left: 0;
        padding: $space-intermediate $space-base;
        right: inherit;
        top: inherit;
        width: 100%;
      }
      @include hpadding(0, $space-large);
      @include vpadding($space-s-large, $space-s-large);
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;

      .dea-cross-link {
        @include media("<m") {
          padding: 0;
        }
        @include media("<=s") {
          margin-left: inherit;
          margin-right: auto;
        }
        margin-left: auto;
        max-width: $hero-box-buttons-width;

        & + .dea-cross-link {
          margin-top: $space-m-small;
        }
      }

      .dea-cross-link__button {
        padding: $space-small $space-small;
        width: 100%;
      }

      .dea-cross-link__label {
        @include dea-heading-medium-s;
      }
    }
  }
}

@mixin stylize-second-hero-box() {
  @include media("<=m") {
    height: $hero-big-image-tablet-height;
  }
  @include media("<=s") {
    box-shadow: $hero-box-shadow-box-large;
    height: auto;
  }
  background-color: $hero-second-box-color;
  border-radius: $border-radius-medium;
  box-shadow: $hero-box-shadow-box-small;
  grid-area: first-box;
  height: $hero-big-image-height;

  a {
    @include media("<=s") {
      height: auto;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .hero-box__image {
    @include media("<=m") {
      height: $hero-small-image-tablet-height;
    }
    @include media("<=s") {
      height: 0;
      padding-bottom: 50%;
    }
    background-position: center;
    background-size: cover;
    border-radius: $border-radius-medium;
    height: $hero-small-image-height;
    width: 100%;
  }

  .hero-box__separator {
    background-color: $dea-color-white;
  }

  .hero-box__text-container {
    @include media("<=m") {
      @include hpadding($space-ml-small, $space-ml-small);
      @include vmargin($space-s-large, 0);
    }
    @include media("<=s") {
      @include hpadding($space-small, $space-small);
      @include vpadding($space-m-small, $space-m-small);
      @include vmargin(auto, auto);
    }
    @include hpadding($space-intermediate, $space-intermediate);
    @include vmargin($space-x-large, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hero-box__title {
    @include media("<=m") {
      @include dea-heading-medium-m;
      line-height: $line-height-m;
    }
    @include media("<=s") {
      @include vmargin(0, 0);
      @include dea-heading-medium-xl;
    }
    @include dea-heading-medium-ml;
    @include vmargin($space-base, $space-m-small);
    color: $dea-color-white;
    line-height: 1.2;
  }

  .hero-box__subtitle {
    color: $dea-color-white;
  }
}

@mixin stylize-bottom-hero-box($box-width) {
  @include media("<=s") {
    box-shadow: $hero-box-shadow-box-large;
    flex-direction: column;
    width: 100%;
  }
  border-radius: $border-radius-medium;
  box-shadow: $hero-box-shadow-box-small;
  display: flex;
  flex-direction: row-reverse;
  width: $box-width;

  a {
    @include media("<=s") {
      flex-direction: column;
    }
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }

  .hero-box__image {
    @include media("<=m") {
      max-width: $hero-small-image-tablet-width;
      min-height: $hero-small-bottom-image-tablet-height;
      width: $hero-small-image-tablet-width;
    }
    @include media("<=s") {
      height: 0;
      max-width: 100%;
      padding-bottom: 50%;
      width: 100%;
    }
    background-position: center;
    background-size: cover;
    border-radius: $border-radius-medium;
    max-width: 50%;
    min-height: $hero-small-image-height;
    width: $hero-big-text-width;
  }

  .hero-box__separator {
    background-color: $dea-color-white;
  }

  .hero-box__text-container {
    @include media("<=m") {
      @include hpadding($space-m-small, $space-m-small);
    }
    @include media("<=s") {
      @include hpadding($space-small, $space-small);
      @include vpadding($space-m-small, $space-m-small);
    }
    @include hpadding($space-intermediate, $space-intermediate);
    @include vpadding($space-base, $space-base);
    flex: 1;
  }

  .hero-box__title {
    @include media("<=m") {
      @include dea-heading-medium-s;
    }
    @include media("<=s") {
      @include vmargin(0, 0);
      @include dea-heading-medium-xl;
    }
    @include dea-heading-medium-l;
    @include vmargin($space-small, $space-x-small);
    color: $dea-color-white;
  }

  .hero-box__subtitle {
    color: $dea-color-white;
  }
}

.hero {
  @include vmargin(0, $space-x-large);

  &-box {
    &__separator {
      @include media("<=s") {
        display: none;
      }
      border-radius: $space-xxx-small;
      display: block;
      height: $space-xxx-small;
    }

    &__subtitle {
      @include media("<=s") {
        @include vmargin($space-small, 0);
      }
      @include dea-heading-light-xs;
      @include vmargin(0, 0);
      font-weight: bold;
    }
  }

  &-container {
    @include media("<=m") {
      &--1,
      &--2,
      &--3,
      &--4 {
        @include hpadding($space-base, $space-base);
      }
    }

    &--1 {
      display: flex;
      flex-direction: column;
      width: 100%;

      .hero-box {
        @include stylize-first-hero-box($hero-big-image-width);
        @include vmargin($space-base, 0);
      }
    }

    &--2 {
      @include media("<=s") {
        display: flex;
        flex-direction: column;
      }
      display: grid;
      grid-gap: $space-base;
      grid-template-areas: "first-box first-box first-box second-box second-box second-box second-box second-box second-box second-box second-box second-box";
      grid-template-columns: repeat(12, 1fr);
      width: 100%;

      .hero-box {
        &:nth-child(1) {
          @include stylize-first-hero-box(100%);
          @include vmargin($space-base, 0);
        }

        &:nth-child(2) {
          @include stylize-second-hero-box();
          @include vmargin($space-base, 0);
        }
      }
    }

    &--3 {
      @include media("<=s") {
        display: flex;
        flex-direction: column;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .hero-box {
        &:nth-child(1) {
          @include stylize-first-hero-box($hero-big-image-width);
          @include vmargin($space-base, $space-base);
        }

        &:nth-child(2),
        &:nth-child(3) {
          @include stylize-bottom-hero-box($hero-bottom-box-width);
        }

        &:nth-child(2) {
          @include media("<=s") {
            margin-bottom: $space-base;
          }
          background-color: $hero-second-box-color;
        }

        &:nth-child(3) {
          background-color: $hero-third-box-color;
        }
      }
    }

    &--4 {
      @include media("<=s") {
        display: flex;
        flex-direction: column;
      }
      display: grid;
      grid-gap: $space-base;
      // sass-lint:disable indentation
      grid-template-areas:
        "first-box first-box first-box second-box second-box second-box second-box second-box second-box second-box second-box second-box"
        "third-box third-box third-box third-box third-box third-box fourth-box fourth-box fourth-box fourth-box fourth-box fourth-box";
      // sass-lint:enable indentation
      grid-template-columns: repeat(12, 1fr);
      width: 100%;

      .hero-box {
        &:nth-child(1) {
          @include stylize-first-hero-box(100%);
          @include vmargin($space-base, 0);
        }

        &:nth-child(2) {
          @include media("<=s") {
            @include vmargin(0, 0);
          }
          @include stylize-second-hero-box();
          @include vmargin($space-base, 0);
          background-color: $hero-second-box-color;
        }

        &:nth-child(3) {
          background-color: $hero-third-box-color;
          grid-area: third-box;
        }

        &:nth-child(4) {
          background-color: $hero-fourth-box-color;
          grid-area: fourth-box;
        }

        &:nth-child(3),
        &:nth-child(4) {
          @include stylize-bottom-hero-box(100%);
        }
      }
    }
  }
}

.text-logo-link-container {
  @include media("<=s") {
    @include hpadding($space-base, $space-base);
  }
  @include vmargin(0, $space-large);
  text-align: center;

  &--with-bg-color {
    @include vpadding($space-s-large, $space-s-large);

    &.text-logo-link-container--bottom-image {
      padding-bottom: 0;
    }
  }

  &__subtitle {
    @include dea-heading-medium-xs;
    @include vmargin($space-s-large, $space-base);
    border-bottom: $border-bold $dea-color-chip-background;
    line-height: $line-height-small;
    text-align: left;

    &-container {
      width: 100%;
    }
  }

  &__header {
    @include hmargin(auto, auto);
    width: 100%;
  }

  &__title {
    @include dea-heading-medium-xxl;
    @include vmargin(0, 0);
  }

  &__text {
    @include media("<=m") {
      padding: 0 10%;
    }
    @include media("<=s") {
      padding: 0;
    }
    padding: 0 17%;
    width: 100%;

    p {
      @include dea-heading-regular-m;
      @include vmargin($space-base, 0);
      line-height: $line-height-m;
    }

    .dea-links__icon {
      margin-left: $space-x-small;
    }
  }

  &__see-all-link {
    @include hmargin(auto, auto);
    @include vmargin($space-base, 0);

    .dea-links__icon {
      margin-left: $space-x-small;
    }
  }

  &__bottom-image {
    @include vmargin($space-m-small, 0);
    @include hmargin(auto, auto);
    height: auto;
    max-width: 100%;
  }

  &--who-we-are {
    .text-logo-link-container__children {
      @include media("<=m") {
        padding: 0 10%;
      }
      @include media("<=s") {
        padding: 0;
      }
      @include media("<=sm") {
        grid-gap: $space-intermediate;
        grid-template-columns: repeat(2, 1fr);
      }
      @include vmargin($space-intermediate, 0);
      display: grid;
      grid-column-gap: $space-base;
      grid-template-columns: repeat(4, 1fr);
      padding: 0 17%;
      width: 100%;
    }
  }

  &--future-learning {
    @include media("<=s") {
      @include hpadding(0, 0);
    }

    .text-logo-link-container__header,
    .text-logo-link-container__text {
      @include media("<=s") {
        @include hpadding($space-base, $space-base);
      }
    }

    .text-logo-link-container__children {
      @include media("<=m") {
        @include hpadding($space-base, $space-base);
        grid-template-columns: repeat(3, 1fr);
      }
      @include media("<=s") {
        @include hpadding($space-base, $space-base);
        @include vpadding(0, $space-xm-small);
        grid-template-columns: repeat(6, 1fr);
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        width: $grid-xl;
      }
      @include vmargin($space-intermediate, 0);
      display: grid;
      grid-gap: $space-base;
      grid-template-columns: repeat(6, 1fr);
      width: 100%;
    }
  }

  &--formazione {
    .text-logo-link-container__subtitle-container {
      @extend .wrapper-content;
      @include media("<=m") {
        @include hpadding($space-base, $space-base);
        @include hmargin(0, 0);
        max-width: 100%;
      }
      @include media("<=s") {
        @include hpadding(0, 0);
      }
    }

    .text-logo-link-container__children {
      @extend .wrapper-content;
      @include media("<=m") {
        @include hpadding($space-base, $space-base);
        @include hmargin(0, 0);
        max-width: 100%;
      }
      @include media("<=s") {
        @include hpadding(0, 0);
        grid-template-columns: 1fr;
      }
      display: grid;
      grid-gap: $space-base;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--dlive {
    .text-logo-link-container__children {
      @extend .wrapper-content;
      @include media("<=m") {
        @include hpadding($space-base, $space-base);
        @include hmargin(0, 0);
        max-width: 100%;
      }
      @include media("<=s") {
        @include hpadding(0, 0);
        grid-gap: $space-small;
        grid-template-columns: 1fr;
      }
      @include vmargin($space-intermediate, 0);
      display: grid;
      grid-gap: $space-large;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--dlive-latest {
    .text-logo-link-container__buttons-container {
      @include media("<=s") {
        display: none;
      }
      @include vmargin($space-base, $space-small);
      @include hmargin(auto, auto);
      display: flex;

      a {
        @include dea-heading-medium-xxxs;
        @include vpadding($space-xs-small, $space-xs-small);
        @include hpadding($space-small, $space-small);
        align-items: flex-end;
        border: $border-light $dea-color-grey-dark;
        border-radius: $border-radius-medium;
        color: $dea-color-grey-dark;
        display: flex;

        .dea-links__icon {
          font-size: $h-font-size-xx-small;
          margin-left: $space-xx-small;
        }

        & + a {
          margin-left: $space-xm-small;
        }
      }
    }

    .text-logo-link-container__children {
      @include media("<=m") {
        @include hpadding($space-base, $space-base);
        @include hmargin(0, 0);
        grid-template-columns: repeat(2, 1fr);
        max-width: 100%;
      }
      @include media("<=s") {
        @include hpadding(0, 0);
        width: 100%;
      }
      @include media("<=sm") {
        grid-template-columns: 1fr;
      }
      @include vmargin($space-intermediate, 0);
      display: grid;
      grid-gap: $space-base;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--video {
    @include media("<=s") {
      @include hpadding(0, 0);
    }

    .text-logo-link-container__header,
    .text-logo-link-container__text {
      @include media("<=s") {
        @include hpadding($space-base, $space-base);
      }
    }

    .text-logo-link-container__subtitle-container {
      @include media("<=m") {
        @include hpadding($space-base, $space-base);
        @include hmargin(0, 0);
        max-width: 100%;
      }
    }

    .text-logo-link-container__children {
      @include media("<=m") {
        @include hpadding($space-base, $space-base);
        grid-template-columns: repeat(2, 1fr);
      }
      @include media("<=s") {
        @include hpadding($space-base, $space-base);
        @include vpadding(0, $space-xm-small);
        grid-template-columns: repeat(4, 1fr);
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        width: $grid-xl;
      }
      @include vmargin(0, 0);
      display: grid;
      grid-gap: $space-base;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
    }
  }

  &--collaborations {
    .text-logo-link-container__header {
      @extend .xs-12;

      .text-logo-link-container__title {
        color: #9b4c8e;
      }
    }

    .text-logo-link-container__text {
      @extend .xs-12;
    }

    .text-logo-link-container__children {
      @extend .xs-12;
      @include media("<=s") {
        grid-template-columns: 1fr;
      }
      @include vmargin($space-intermediate, $space-intermediate);
      display: grid;
      grid-gap: $space-base;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    }
  }
}

.who-we-are-box {
  &__title {
    @include vmargin($space-small, 0);
    @include dea-heading-upper-xxs;
    letter-spacing: $letter-spacing-large;
    line-height: $line-height-small;
  }
}

.future-learning-box {
  @include media("<=s") {
    min-width: $text-logo-link-box-learning-min-width-mobile;
    scroll-snap-align: center;
  }

  &--link {
    &:hover {
      border-color: transparent;
      box-shadow: $text-logo-link-box-box-shadow;
    }
  }
  border: $border-light $dea-color-chip-background;
  border-radius: $text-logo-link-box-border-radius;
  display: flex;
  flex-direction: column;
  padding: $space-base $space-xm-small;

  a {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__image {
    align-self: center;
  }

  &__text {
    &--link {
      display: flex;
      flex-direction: column;
    }
    flex: 1;
    margin-top: $space-small;
  }

  &__title {
    @include vmargin(0, 0);
    @include dea-heading-upper-xxs;
    letter-spacing: $letter-spacing-large;
    line-height: $line-height-small;
    margin-top: auto;
  }

  &__link {
    @include vmargin($space-small, 0);

    span {
      @include dea-heading-regular-xxs;
      border-bottom: $border-bold $dea-color-grey-dark;
    }
  }
}

.formazione-box {
  background-color: $dea-color-white;
  border-radius: $border-radius-large;
  box-shadow: $text-logo-link-box-formazione-box-shadow;

  &__image {
    @include media("<m") {
      height: $text-logo-link-box-formazione-image-height-tablet;
    }
    @include media("<=s") {
      height: $text-logo-link-box-formazione-image-height;
    }
    background-position: center;
    background-size: cover;
    border-radius: $border-radius-large $border-radius-large 0 0;
    height: $text-logo-link-box-formazione-image-height;
    width: 100%;
  }

  &__text {
    @include vpadding($space-ml-small, $space-s-large);
    @include hpadding($space-small, $space-small);
    text-align: left;
  }

  &__title {
    @include dea-heading-medium-l;
    @include vmargin(0, 0);
  }

  &__subtitle {
    @include dea-heading-light-xs;
    @include vmargin($space-xm-small, 0);
    font-weight: bold;
  }
}

.dlive-box {
  @include media("<=s") {
    @include vpadding($space-ml-small, $space-ml-small);
    @include hpadding($space-ml-small, $space-base);
    align-items: center;
    box-shadow: $text-logo-link-box-box-shadow;
    flex-direction: row;
  }

  &:hover {
    box-shadow: $text-logo-link-box-box-shadow;
  }

  display: flex;
  flex-direction: column;
  padding: $space-intermediate;

  &__image {
    @include media("<=s") {
      width: $text-logo-link-box-dlive-logo-width-table;
    }
    height: auto;
    width: $text-logo-link-box-dlive-logo-width;
  }

  &__text {
    @include media("<=sm") {
      display: none;
    }
    @include media("<=s") {
      @include hpadding($space-intermediate, $space-intermediate);
    }
  }

  &__title {
    @include media("<=s") {
      @include vmargin(0, 0);
    }
    @include vmargin($space-base, $space-intermediate);
    @include dea-heading-regular-xs;
    line-height: $line-height-s;
  }

  &__link {
    @include media("<=sm") {
      margin-left: auto;
    }
    @include media("<=s") {
      margin-top: 0;
    }
    margin-top: auto;

    .dea-cross-link.w-card {
      @include media("<=s") {
        width: max-content;
      }
      text-decoration: none;

      .dea-cross-link__button.white-button {
        min-width: auto;

        .dea-cross-link__label {
          line-height: $line-height-small;
        }
      }
    }
  }
}

.dlive-latest-box {
  @include media("<=s") {
    border: $border-light $dea-color-grey-medium-light;
    border-radius: $text-logo-link-box-dlive-latest-mobile-border-radius;
    box-shadow: $text-logo-link-box-dlive-latest-mobile-box-shadow;
  }
  border-radius: $border-radius-large;
  box-shadow: $box-shadow-medium;

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    @include media("<=s") {
      display: block;
      padding: $space-x-small $space-xm-small;
    }
    display: none;
  }

  &__feed-name {
    @include dea-heading-medium-xs;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .dea-links__icon {
      font-size: $h-font-size-large;
    }
  }

  &__image {
    @include media("<=s") {
      border-radius: 0;
    }
    background-position: center;
    background-size: cover;
    border-radius: $border-radius-large $border-radius-large 0 0;
    height: $text-logo-link-box-dlive-latest-image-height;
    width: 100%;
  }

  &__content {
    @include media("<=s") {
      border: 0;
      border-radius: 0;
      flex: 0;
    }
    border-bottom: $border-light $dea-color-grey-medium-light;
    border-left: $border-light $dea-color-grey-medium-light;
    border-radius: 0 0 $border-radius-large $border-radius-large;
    border-right: $border-light $dea-color-grey-medium-light;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $space-small $space-m-small;
    text-align: left;
  }

  &__title {
    @include vmargin(0, $space-x-small);
    @include dea-heading-medium-s;
  }

  &__logo {
    height: auto;
    margin-left: auto;
    margin-top: auto;
    width: $text-logo-link-box-dlive-latest-logo-width;
  }
}

.video-box {
  @include media("<=s") {
    min-width: $text-logo-link-box-video-min-width-mobile;
    scroll-snap-align: center;
  }
  border: $border-light $dea-color-chip-background;
  border-radius: $text-logo-link-box-border-radius;
  display: flex;
  flex-direction: column;

  &__preview-image {
    border-radius: $text-logo-link-box-border-radius;
  }

  &__video-container {
    position: relative;

    .play-icon {
      background: transparent;
      border: 0;
      cursor: pointer;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      &:hover + .video-box__preview-image {
        filter: brightness(65%);
      }
    }
  }

  &__video-wrapper {
    display: none;
  }

  &__text-container {
    padding: $space-small;
    text-align: left;
  }

  &__title {
    @include dea-heading-upper-xxs;
    @include vmargin(0, 0);
  }

  &__description {
    @include vmargin($space-xs-small, 0);

    p {
      @include h-font-size-xx-small;
      @include vmargin(0, 0);
      font-family: $font-primary-light;
    }
  }

  &__video-wrapper {
    height: 0;
    padding-bottom: 49.25%; /* 16:9 */
    position: relative;

    iframe,
    video {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
    width: auto;
  }
}

.collaboration-box {
  &__image {
    border-radius: $text-logo-link-box-border-radius;
    height: auto;
    max-width: 100%;
    width: 100%;
  }
}

.catalog-box {
  @include media("<m") {
    @include hpadding($space-base, $space-base);
  }
  @include media("<=s") {
    @include hpadding(0, 0);
  }
  @include vmargin(0, $space-x-large);
  @include stylize-search-form(
    $space-small,
    $space-base,
    $catalog-box-border-radius,
    $space-xs-small,
    $space-x-small,
    $space-xs-small,
    $line-height-heading,
    $catalog-box-search-border-radius
  );

  &__container {
    @include media("<=s") {
      border-radius: 0;
      flex-direction: column;
      height: $catalog-box-height-mobile;
      justify-content: center;
      padding: $space-xm-small $space-x-large;
    }
    background-position: center;
    background-size: cover;
    border-radius: $catalog-box-border-radius;
    display: flex;
    height: $catalog-box-height;
    padding: $space-intermediate;
  }

  &__left,
  &__right {
    @include media("<=s") {
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__left {
    @include media("<=s") {
      border-right: 0;
      padding-right: 0;
    }
    border-right: $border-bold $catalog-box-border-color;
    padding-right: $space-intermediate;
  }

  .wysiwyg {
    .dea-cross-link {
      @include media("<=s") {
        align-self: flex-start;
        margin-top: $space-small;
        width: max-content;
      }
      align-self: flex-end;
      margin-top: auto;
      text-decoration: none;

      .dea-cross-link__button.dark-button {
        min-width: auto;
        padding: $space-x-small $space-small;

        .dea-cross-link__label {
          line-height: $line-height-small;
        }
      }
    }
  }

  &__right {
    @include media("<=s") {
      @include vmargin($space-base, 0);
      padding-left: 0;
    }
    padding-left: $space-intermediate;
  }

  &__title {
    @include dea-heading-medium-xxl;
    @include vmargin(0, 0);
    line-height: $line-height-l;
  }

  &__secondary-title {
    @include dea-heading-medium-l;
    @include vmargin(auto, 0);
  }
}

.w-catalog-filter {
  @include hpadding($space-base, $space-base);
  margin-bottom: $space-s-large;

  &__container {
    @include media("<s") {
      width: 100%;
    }
    margin: 0 auto;
    max-width: 100%;
    width: $catalog-hero-form-width;

    .w-enhanced-title {
      @include dea-heading-upper-xxs;
      @include vmargin(0, $space-large);
      text-align: center;
    }
  }

  &__buttons-container {
    @include media("<s") {
      display: flex;
      flex-direction: column;
    }
    display: grid;
    grid-gap: $space-base;
    grid-template-columns: repeat(2, 1fr);

    .dea-cross-link {
      @include media("<s") {
        margin: 0 auto;
        max-width: 100%;
        width: $catalog-filter-button-width;
      }
      box-shadow: $box-shadow-light;
      padding: 0;
      width: 100%;

      &:hover {
        button {
          background-color: $dea-color-grey-dark;

          .dea-cross-link__label,
          .dea-cross-link__arrow-icon {
            color: $dea-color-white;
          }

          svg path {
            fill: $dea-color-white;
          }
        }
      }

      &__button {
        @include media("<=xs") {
          padding: $space-m-small;
        }
        padding: $space-base;
        width: 100%;

        svg {
          height: $catalog-filter-svg-height;
        }
      }

      &__label {
        @include media("<m") {
          @include dea-heading-medium-l;
        }
        @include media("<=s") {
          @include hmargin($space-intermediate, $space-intermediate);
        }
        @include media("<=xs") {
          @include hmargin($space-m-small, $space-m-small);
        }
        @include hmargin($space-intermediate, $space-s-large);
      }
    }
  }
}

.site-header {
  position: static !important; //sass-lint:disable-line no-important
}

.dea-cross-link {
  &.dea-cross-link__button--blue {
    background: $dea-text-blue;
    border: 0;
    border-radius: $border-radius-8;
    min-width: auto;
    padding: $space-10px $space-xm-small $space-10px $space-small;

    .dea-cross-link__label {
      color: $dea-color-white;
      font-family: "LatoRegular";
      font-size: $body16-font-size;
      font-weight: $font-weight-bold;
      line-height: $body16-line-height;
      margin-left: 0;
      margin-right: $space-x-small;
    }

    &.dea-cross-link__button {
      width: fit-content;
    }
  }

  &.w-card {
    margin-bottom: 0;
  }
}

.dea-launch-two-images {
  @include media("<m") {
    padding: 0 $space-base;
  }

  margin-bottom: $space-x-large;

  &__container {
    @include media("<m") {
      background-size: 50% 100%;
    }
    @include media("<=s") {
      background-image: none !important; //sass-lint:disable-line no-important
      flex-direction: column;
    }

    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: $border-radius-8;
    display: flex;
  }

  &__left-content {
    @include media("<m") {
      padding-right: 0;
      width: 60%;
    }
    @include media("<=s") {
      margin-top: $space-m-small;
      order: 1;
      padding: 0 $space-intermediate $space-s-large $space-intermediate;
      width: 100%;
    }
    @media screen and (max-width: $screen-540px) {
      margin-top: $space-xm-small;
      padding: 0 $space-base $space-intermediate $space-base;
    }

    padding: $space-56px $space-intermediate $space-56px $space-56px;
    width: 50%;
  }

  &__title-container {
    align-items: center;
    display: flex;
  }

  &__logo {
    @media screen and (max-width: $screen-540px) {
      height: $dea-fic-launch-logo-mobile-size;
      margin-right: $space-xm-small;
      width: $dea-fic-launch-logo-mobile-size;
    }

    margin-right: $space-m-small;
  }

  &__title {
    @media screen and (max-width: $screen-540px) {
      font-size: $body24-font-size;
      line-height: $body24-line-height;
    }

    font-family: "LatoRegular";
    font-size: $h2-font-size;
    font-weight: $font-weight-800;
    line-height: $line-height-heading;
    margin: 0;
  }

  &__text-container {
    margin-top: $space-base;

    > * {
      color: inherit;
      font-family: "LatoRegular";
      font-size: $body16-font-size;
      line-height: $body16-line-height;
      margin: 0;
    }
  }

  &__cta-container {
    margin-top: $space-base;
  }

  &__right-content {
    @include media("<m") {
      width: 40%;
    }
    @include media("<=s") {
      height: $dea-fic-launch-image-mobile-height;
      width: 100%;
    }

    position: relative;
    width: 50%;
  }

  &__picture {
    @include media("<m") {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    @include media("<=s") {
      height: 100%;
      left: 0;
      position: relative;
      top: 0;
      transform: none;
      width: auto;
      z-index: 1;
    }
    @media screen and (max-width: $screen-540px) {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    bottom: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__right-background-image {
    @include media("<=s") {
      height: 100%;
    }
    @include media(">s") {
      display: none;
    }

    bottom: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
  }
}
