// Grid

/**
 * Use this class whenever you want to start a grid section.
 * It is possible to use columns classes outside the grid, just if you need to handle the width of your elements.
 * NEVER ever use grid as a simple container! The only allowed direct children of a grid element are columns elements.
 *
 * @molecule .grid
 * @section Grid > Grid classes
 * @markup
 *  <div class="grid">
 *  <div class="s-7"></div>
 *  </div>
 */
// sass-lint:disable mixins-before-declarations
.grid {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;

  .grid {
    margin-left: -($gutter-xs / 2);
    width: calc(100% + #{$gutter-xs});
  }

  @include media(">=xs") {
    width: $grid-xs;

    .grid {
      margin-left: -($gutter-xs / 2);
      width: calc(100% + #{$gutter-xs});
    }
  }

  @include media(">=s") {
    width: $grid-s;

    .grid {
      margin-left: -($gutter-s / 2);
      width: calc(100% + #{$gutter-s});
    }
  }

  @include media(">=m") {
    width: $grid-m;

    .grid {
      margin-left: -($gutter-m / 2);
      width: calc(100% + #{$gutter-m});
    }
  }

  @include media(">=l") {
    width: $grid-l;

    .grid {
      margin-left: -($gutter-l / 2);
      width: calc(100% + #{$gutter-l});
    }
  }

  @include media(">=xl") {
    width: $grid-xl;

    .grid {
      margin-left: -($gutter-xl / 2);
      width: calc(100% + #{$gutter-xl});
    }
  }
}
// sass-lint:enable mixins-before-declarations

// Columns
@mixin column($size) {
  box-sizing: border-box;
  display: block;
  padding-left: $size / 2;
  padding-right: $size / 2;
}

@mixin column-width($n, $grid) {
  width: percentage((1 / $grid) * $n);
}

@mixin xs-grid {
  @for $i from 1 through $xs-grid {
    .xs-#{$i} {
      @include column-width($i, $xs-grid);
    }
  }
}

@mixin s-grid {
  @for $i from 1 through $s-grid {
    .s-#{$i} {
      @include column-width($i, $s-grid);
    }
  }
}

@mixin m-grid {
  @for $i from 1 through $m-grid {
    .m-#{$i} {
      @include column-width($i, $m-grid);
    }
  }
}

@mixin l-grid {
  @for $i from 1 through $l-grid {
    .l-#{$i} {
      @include column-width($i, $l-grid);
    }
  }
}

@mixin xl-grid {
  @for $i from 1 through $xl-grid {
    .xl-#{$i} {
      @include column-width($i, $xl-grid);
    }
  }
}

// Columns offsets

@mixin xs-grid-offset {
  @for $i from 0 through $xs-grid {
    .off-xs-#{$i} {
      margin-left: percentage((1 / $xs-grid) * $i);
    }
  }
}

@mixin s-grid-offset {
  @for $i from 0 through $s-grid {
    .off-s-#{$i} {
      margin-left: percentage((1 / $s-grid) * $i);
    }
  }
}

@mixin m-grid-offset {
  @for $i from 0 through $m-grid {
    .off-m-#{$i} {
      margin-left: percentage((1 / $m-grid) * $i);
    }
  }
}

@mixin l-grid-offset {
  @for $i from 0 through $l-grid {
    .off-l-#{$i} {
      margin-left: percentage((1 / $l-grid) * $i);
    }
  }
}

@mixin xl-grid-offset {
  @for $i from 0 through $xl-grid {
    .off-xl-#{$i} {
      margin-left: percentage((1 / $xl-grid) * $i);
    }
  }
}

/**
 * Use this class on a div outside the columns when you want an
 * element to go on a new line and his width is not 100%.
 *
 * @molecule .row
 * @section Grid > Row classes
 * @modifiers
 *   .row--centered to center the row content
 * @markup
 *   <div class="grid">
 *   <div class="row">
 *     <div class="xs-7" style="margin-bottom: 16px;">
 *     <a class="btn btn--block btn--small">.xs-7</a>
 *     </div>
 *   </div>
 *   <div class="row row--centered">
 *     <div class="xs-7" style="margin-bottom: 16px;">
 *     <a class="btn btn--block btn--small">.xs-7 in a .row--centered</a>
 *     </div>
 *   </div>
 *   </div>
 */
.row {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &--centered {
    justify-content: center;
  }
}

/**
 * The grid columns are generated by (private) mixins.
 * Expand the code example to see how to use columns.
 *
 * @molecule Grid Columns
 * @section Grid > Column classes
 * @markup
 *  <div class="grid">
 *  <div class="xs-12 m-12" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-12</a>
 *  </div>
 *  <div class="xs-12 m-10" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-10</a>
 *  </div>
 *  <div class="xs-12 m-2" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-2</a>
 *  </div>
 *  <div class="xs-12 m-8" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-8</a>
 *  </div>
 *  <div class="xs-12 m-4" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-4</a>
 *  </div>
 *  <div class="xs-12 m-6" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-6</a>
 *  </div>
 *  <div class="xs-12 m-6" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-6</a>
 *  </div>
 *  <div class="xs-12 m-4" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-4</a>
 *  </div>
 *  <div class="xs-12 m-8" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-8</a>
 *  </div>
 *  <div class="xs-12 m-2" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-2</a>
 *  </div>
 *  <div class="xs-12 m-10" style="margin-bottom: 16px">
 *    <a class="btn btn--block btn--small">.xs-12 .m-10</a>
 *  </div>
 *  </div>
 */

// Extra small grid
@include xs-grid;
@include xs-grid-offset;
@for $i from 1 through $xs-grid {
  .xs-#{$i} {
    @include column($gutter-xs);
  }
}

// Small grid
@include media(">xs") {
  @include s-grid;
  @include s-grid-offset;
  @for $i from 1 through $s-grid {
    .s-#{$i} {
      @include column($gutter-s);
    }
  }
}

// Medium grid
@include media(">s") {
  @include m-grid;
  @include m-grid-offset;
  @for $i from 1 through $m-grid {
    .m-#{$i} {
      @include column($gutter-m);
    }
  }
}

// Large grid
@include media(">m") {
  @include l-grid;
  @include l-grid-offset;
  @for $i from 1 through $l-grid {
    .l-#{$i} {
      @include column($gutter-l);
    }
  }
}

// Extra large grid
@include media(">l") {
  @include xl-grid;
  @include xl-grid-offset;
  @for $i from 1 through $xl-grid {
    .xl-#{$i} {
      @include column($gutter-xl);
    }
  }
}

@include media("<=xs") {
  [class^="xs-"],
  [class^="s-"],
  [class^="m-"],
  [class^="l-"],
  [class^="xl-"] {
    padding-left: $gutter-xs / 2;
    padding-right: $gutter-xs / 2;
  }
}

@include media(">xs", "<=s") {
  [class^="xs-"],
  [class^="s-"],
  [class^="m-"],
  [class^="l-"],
  [class^="xl-"] {
    padding-left: $gutter-s / 2;
    padding-right: $gutter-s / 2;
  }
}

@include media(">s", "<=m") {
  [class^="xs-"],
  [class^="s-"],
  [class^="m-"],
  [class^="l-"],
  [class^="xl-"] {
    padding-left: $gutter-m / 2;
    padding-right: $gutter-m / 2;
  }
}

@include media(">m", "<=l") {
  [class^="xs-"],
  [class^="s-"],
  [class^="m-"],
  [class^="l-"],
  [class^="xl-"] {
    padding-left: $gutter-l / 2;
    padding-right: $gutter-l / 2;
  }
}

@include media(">l") {
  [class^="xs-"],
  [class^="s-"],
  [class^="m-"],
  [class^="l-"],
  [class^="xl-"] {
    padding-left: $gutter-xl / 2;
    padding-right: $gutter-xl / 2;
  }
}
