/////////////////////////////////////
// Background images variants

.dea-target-menu--primaria.dea-target-menu--docenti > .wrapper {
  background-image: url("../img/docenti-menu-plugin-primaria.jpg");

  .dea-target-menu__container.dea-target-menu--primaria {
    max-height: 140px;
  }
}

.dea-target-menu--secondaria-di-primo-grado.dea-target-menu--docenti > .wrapper {
  background-image: url("../img/docenti-menu-plugin-secondaria-primo-grado.jpg");
}

.dea-target-menu--secondaria-di-secondo-grado.dea-target-menu--docenti > .wrapper {
  background-image: url("../img/docenti-menu-plugin-secondaria-primo-grado.jpg");
}

// These are ported from storybook but redefined here, as Educazione Civica menu behave
// a bit differently from the other ones.
.dea-header--bottom {
  .dea-header--bottom__links.dea-header--bottom__links--target-color-ec:hover {
    border-bottom-color: $dea-color-target-ddi !important; // sass-lint:disable-line no-important
  }

  &__third-level {
    &.dea-header--bottom__button.dea-border-target-ec:not(a):hover,
    &.dea-header--bottom__button.dea-border-target-ec:not(a):active {
      border-bottom-color: $dea-color-target-ddi !important; // sass-lint:disable-line no-important
    }
  }
}
