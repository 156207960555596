.w-slide--hero {
  margin-bottom: $space-x-large;

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .slick-prev {
    @include media("<=sm") {
      left: $space-x-small;
    }

    left: $space-base;
    transform: translateY(-50%) rotate(180deg);
  }

  .slick-next {
    @include media("<=sm") {
      right: $space-x-small;
    }

    right: $space-base;
  }

  .slick-slide {
    .w-card {
      margin-bottom: 0;
    }

    .dea-hero__payoff {
      @include media("<m") {
        margin-top: $space-x-large;
        padding-top: 0;
      }

      @include media("<=sm") {
        @include hmargin($space-x-large, $space-x-large);
      }

      @include hmargin($dea-size-x-large, $dea-size-x-large);
    }
  }
}
