$minisite-close-button-top: 45px;
$minisite-back-button-top: 50px;

.w-section {
  margin-top: $space-base;
}

.page-header {
  &__container-with-min-height {
    min-height: 460px;

    &.page-header__container--height-s,
    &.page-header__container--height-t {
      margin: -$space-x-large 0;
      min-height: $header-monocolor-height-t;
    }
  }

  &__container--height-s {
    height: $header-monocolor-height-s;
  }

  &__container--height-t {
    height: $header-monocolor-height-t;
  }
}

.site-header {
  .dea-main-header__submenu-container {
    @include media(">m", "<=l") {
      display: block;
    }
  }

  .mobile-menu {
    @include media(">m") {
      display: none;
    }
  }
}

.mobile-menu {
  &.mobile-menu--target-ec,
  &.mobile-menu--target-ddi,
  &.mobile-menu--target-eds,
  &.mobile-menu--target-tdi,
  &.mobile-menu--target-ross,
  &.mobile-menu--target-eaf {
    .mobile-menu__close {
      top: $minisite-close-button-top;
    }

    .mobile-menu__back {
      top: $minisite-back-button-top;
    }
  }
}
